import './App.css';
import Incoming from './Components/Incoming/Incoming';

function App() {
  return (
    <div className="App">
      <Incoming />
    </div>
  );
}

export default App;
